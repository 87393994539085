// images referenced from within DAE need to be resolved (see loadingmanager url hook)
import React from 'react';
import ReactGA from 'react-ga';
import './App.css';
import cubeDae from './assets/cub.DAE';
import img0 from './assets/m-windows.jpg';
import img1 from './assets/m-innercube.jpg';
import img2 from './assets/m-buttons.png';
import img3 from './assets/m-apptrans.png';
import swipeIcon from './assets/pointer2.png';

import imgRef0 from './assets/refmap_cube_outback.png';
import imgRef1 from './assets/refmap_cube_outbottom.png';
import imgRef2 from './assets/refmap_cube_outfront.png';
import imgRef3 from './assets/refmap_cube_outleft.png';
import imgRef4 from './assets/refmap_cube_outright.png';
import imgRef5 from './assets/refmap_cube_outtop.png';

import imgRefG5 from './assets/refmap_glass_outback.png';
import imgRefG2 from './assets/refmap_glass_outbottom.png';
import imgRefG4 from './assets/refmap_glass_outfront.png';
import imgRefG1 from './assets/refmap_glass_outleft.png';
import imgRefG0 from './assets/refmap_glass_outright.png';
import imgRefG3 from './assets/refmap_glass_outtop.png';

import * as THREE from './three.module';

import {ColladaLoader} from './ColladaLoader';

import {OrbitControls} from './OrbitControls';

import {TweenLite, Power1, Power4, Linear} from 'gsap/TweenLite';

import {EffectComposer} from './EffectComposer.js';
import {SSAARenderPass} from './SSAARenderPass.js';

require("./RectAreaLightUniformsLib.js");

//
// BUILD OPTIONALS
//
var isDEBUG = false;
var dat = null;
var Stats = null;

// COMMENT OUT THESE LINES FOR PRODUCTION (removes these sources from bundle)
// dat = require('./dat.gui.min');
// Stats = require('./stats.min');
//
//
//


const HALF_PI = Math.PI / 2;
const TWO_PI = Math.PI * 2;
const RAD45 = Math.PI / 4;
const distRots = [0, HALF_PI, -Math.PI, -HALF_PI];

const routes = [
	'provision-inventory',
	'nammu-inventory-matrix',
	'core-document-metrics',
	'nammu-navigation',
	'contact'
];

// array representation of ref image bundle urls (for environment map)
let envURLs = [imgRef0, imgRef1, imgRef2, imgRef3, imgRef4, imgRef5];
let envGURLs = [imgRefG0, imgRefG1, imgRefG2, imgRefG3, imgRefG4, imgRefG5];

const posZero = new THREE.Vector3();

const introInfo = {
	posEnd1: new THREE.Vector3(0, 5, 0),
	posEnd2: new THREE.Vector3(0, -0.4, 5),
	introTime: isDEBUG ? 0 : 7,
	posStart: new THREE.Vector3(0, 20, 0),
	phi: 1.57,
	theta: 0.78,
	maxPolar: 1.6,
	minPolar: 1,
	cubeAngStart: 1.9,
	cubeAngEnd: 0,
	cubeAngEndOffset: -RAD45,
	cubeAngFlip: 0.707,
	flipTime: 1.5,
	flipDelay: 0.67,
	dragDelay: 1,
	dragTime: 1.35,
	swipeDelay: 0.5,
	swipeTime: 2.5
};

let timings = {
	learn_del: 0.7,
	win_fade: 0.6,
	cube_del: 0.5,
	cube_trans1: 1.0,
	cube_trans2: 1.5,
	detailTimes: [
		1,
		21.5,		// PI
		21.5,		// NIM
		21.5,		// CDM
		21.5,		// NN
		1
	],
	detailEndTime: 13
};

class Dom3D extends React.Component {
	
	constructor(props) {
		super(props);
		
		this.animate = this.animate.bind(this);
		this.onWindowResize = this.onWindowResize.bind(this);
		this.onMouseClick = this.onMouseClick.bind(this);
		this.clickCategory = this.clickCategory.bind(this);
		this.handlePlayToggle = this.handlePlayToggle.bind(this);
		
		/*
				if (process.env.REACT_APP_GACODE) {
				 ReactGA.initialize(process.env.REACT_APP_GACODE);
			  }
		*/
	}
	
	componentDidMount() {
		if (process.env.REACT_APP_GACODE) {
			ReactGA.initialize(process.env.REACT_APP_GACODE);
			ReactGA.pageview('/home');
		}
		this.init();
		this.animate();
	}
	
	init() {
		var self = this;
		
		// params
		this.params = {
			shadows: true,
			exposure: 0.8,
			sampleLevel: 2,
			unbiased: true,
			useSSAA: true,
			doorSpeed: 1.5,
			int_light: {
				intensity: 100,
				intensityDark: 10,
				color: 0x0088ff,
				colorDark: 0x0088ff,
				decay: 0.01,
				decayDark: 10
			},
			rotateSpeed: 1.5,
			disableAutoRot: () => {
				self.controls.autoRotate = false;
			},
			tutorialTime: 5
		};
		
		this.faceOpen = [false, false, false, false, false, false];

		// for canceling auto events related to interrupting playback of detail animations
		this.detPending = [];
		
		// scene, lighting setup
		this.container = document.getElementById('component3D');
		
		this.scene = new THREE.Scene();
		
		this.isCubeLoaded = false;
		
		this.camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 100);
		// this.camera.fov = introInfo.fovStart;
		this.camera.fov = 50;
		this.camera.updateProjectionMatrix();
		this.camera.lookAt(posZero);
		
		
		var ambient = new THREE.AmbientLight(0xffffff, 10);
		this.scene.add(ambient);
		
		this.shadowLight = new THREE.DirectionalLight(0, 1);
		this.shadowLight.castShadow = true;
		this.shadowLight.position.y = 1;
		this.shadowLight.lookAt(0, 0, 0);
		this.scene.add(this.shadowLight);
		
		this.rectLight = new THREE.RectAreaLight(0xffffff, 100, 1.666, 1.333);
		this.rectLight.position.set(0, 2.333, 1.667);
		this.rectLight.lookAt(0, 0, 0);
		this.scene.add(this.rectLight);
		// this.rectLight.add( new THREE.RectAreaLightHelper( this.rectLight ) );
		
		this.rectLight2 = new THREE.RectAreaLight(0xffffff, 40, 2.333, 1.333);
		this.rectLight2.position.set(0.5, -2.333, 1.667);
		this.rectLight2.lookAt(0, 0, 0);
		this.scene.add(this.rectLight2);
		// this.rectLight2.add( new THREE.RectAreaLightHelper( this.rectLight2 ) );
		
		// custom loading manager settings
		var manager = new THREE.LoadingManager();
		// intercept urls to remap them to webpack bundle urls
		manager.setURLModifier((url) => {
			// hardcoded texture pathing, only way to include in bundle
			if (url.indexOf('m-windows') >= 0) {
				return img0;
			} else if (url.indexOf('innercube') >= 0) {
				return img1;
			} else if (url.indexOf('buttons') >= 0) {
				return img2;
			} else if (url.indexOf('apptrans') >= 0) {
				return img3;
			}
			// not texture
			return url;
		});
		
		// "floor" - invisible geometry that receives a shadow with transparecy
		var floorMat = new THREE.ShadowMaterial({ opacity: 0.3 });
		var floorGeometry = new THREE.PlaneBufferGeometry(20, 20);
		var floorMesh = new THREE.Mesh(floorGeometry, floorMat);
		floorMesh.receiveShadow = true;
		floorMesh.rotation.x = -HALF_PI;
		floorMesh.position.y = -1.6;
		this.scene.add(floorMesh);
		
		// cube model
		this.onCubeLoad = this.onCubeLoad.bind(this);
		var daeLoader = new ColladaLoader(manager);
		daeLoader.load(cubeDae, this.onCubeLoad);
		
		// the "box" proxy object for casting a solid shadow effect on ground
		var boxGeo = new THREE.BoxGeometry(1, 1, 1, 1, 1, 1);
		var boxMat = new THREE.MeshBasicMaterial({ colorWrite: false, depthWrite: false });
		this.boxMesh = new THREE.Mesh(boxGeo, boxMat);
		this.boxMesh.scale.set(2, 2, 2);
		this.boxMesh.castShadow = true;
		
		// cubeRoot is a blank object used as the rotational root for spinning the scene
		// this allows us to have different scales and default rotations on the shadowbox and the actual cube
		// also allows the cube dae to be Z-up and have its correctional transformation at the cube's scene root
		this.cubeRoot = new THREE.Object3D();
		this.cubeRoot.add(this.boxMesh);
		this.scene.add(this.cubeRoot);

		
		this.pointLight = new THREE.PointLight(this.params.int_light.colorDark, 0, 5, 0.01);
		this.cubeRoot.add(this.pointLight);
		
		
		// cube env map
		var loader = new THREE.CubeTextureLoader();
		loader.load(envURLs, function(tex) {
			self.cubeEnvMap = tex;
			self.isEnvMapLoaded = true;
		});
		
		loader.load(envGURLs, function(tex) {
			self.cubeEnvMapG = tex;
			self.isGEnvMapLoaded = true;
		});
		
		
		this.renderer = new THREE.WebGLRenderer({ alpha: true });
		this.renderer.physicallyCorrectLights = true;
		this.renderer.gammaInput = true;
		this.renderer.gammaOutput = true;
		this.renderer.shadowMap.enabled = true;
		this.renderer.toneMapping = THREE.ReinhardToneMapping;
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setSize(window.innerWidth, window.innerHeight);
		this.renderer.setClearColor(0x00ffff, 0);
		this.container.appendChild(this.renderer.domElement);
		this.renderer.domElement.style.background = 'transparent';
		this.renderer.localClippingEnabled = true;
		
		// postprocessing
		this.composer = new EffectComposer(this.renderer);
		this.composer.setPixelRatio(1); // ensure pixel ratio is always 1 for performance reasons
		this.ssaaRenderPassP = new SSAARenderPass(this.scene, this.camera);
		this.composer.addPass(this.ssaaRenderPassP);
		this.ssaaRenderPassP.sampleLevel = this.params.sampleLevel;
		this.ssaaRenderPassP.unbiased = this.params.unbiased;
		
		// stats
		if (Stats) {
			this.stats = new Stats();
			this.container.parentElement.appendChild(this.stats.dom);
		}
		
		// meshnodes inside cube to populate this map
		this.meshNodes = {};
		
		// orbit controls
		this.controls = new OrbitControls(this.camera, this.renderer.domElement);
		this.setupControls(this.controls);
		
		this.previousShadowMap = false;
		this.spinTime = 0.6;
		this.curQIdx = 0;
		this.changed = false;
		this.startQ = null;
		
		this.category = 0;
		this.catReq = 0;
		
		this.domCopyMain = document.getElementById('copyMain');
		this.domCopyTitle = document.getElementById('copyTitle');
		this.domCopyBasic = document.getElementById('copyTextBasic');
		this.domCopy1 = document.getElementById('copyText1');
		this.domCopy2 = document.getElementById('copyText2');
		this.domCopy3 = document.getElementById('copyText3');
		this.domCopy4 = document.getElementById('copyText4');
		// this.domSwipe = document.getElementById('swipe');
		this.domPlay = document.getElementById('play');
		this.domHMButton = document.getElementById('ham-button');
		this.domSwipeIcon = document.getElementById('swipeIcon');
		this.domMenu = document.getElementById('nMenu');
		this.domCredit = document.getElementById('nCredit');
		this.domSubtitle = document.getElementById('subtitle');
		this.domTutOver = document.getElementById('tut-overlay');
		this.domTutImg = document.getElementById('img-tutorial');
		this.domTimeline = document.getElementById('timeline-wrapper');
		this.domNeedle = document.getElementById('needle');
		
		// tutorial "hack"
		this.domPlay.addEventListener('click', this.handlePlayToggle, false);
		this.domPlay.classList.add("autDark");
		this.showEl(this.domPlay, false);
		this.domTutOver.style.display = 'none';
		this.domTutImg.style.display = 'none';
		
		// event handlers
		// window.addEventListener('resize', this.onWindowResize, false);
		window.addEventListener('click', this.onMouseClick, false);
		
		// this.domDebug1 = document.getElementById('debug1');
		// this.domDebug2 = document.getElementById('debug2');
		
		this.autoPlay = true;
	}
	
	setupControls(controls) {
		controls.enableKeys = controls.enablePan = controls.enableZoom = false;
		controls.minPolarAngle = introInfo.minPolar;
		controls.maxPolarAngle = introInfo.maxPolar;
		controls.enableDamping = true;
		controls.dampingFactor = 0.167;
		controls.rotateSpeed = 0.35;
		controls.autoRotate = false;
		controls.autoRotateSpeed = this.params.rotateSpeed * 0.8;
		controls.enabled = false;
		controls.spherical.phi = introInfo.phi;
		controls.spherical.theta = introInfo.theta;
	}
	
	onCubeLoad(obj) {
		// store whole dae object for animation processing
		this.cubeDAE = obj;
		// dae scene convenience member
		this.cubeScene = obj.scene;
		// sizing adjustment for scene
		// this.cubeScene.scale.set(1.1,1.1,1.1);
		// this.cubeScene.rotation.x = 0;
		
		this.controls.setTargetObject(this.cubeRoot);
		
		// setup animations
		// this.decodeAnimations();
		
		// identify meshNodes
		this.collectMeshNodes();
		
		// setup materials
		this.initMaterials();
		
		this.meshNodes['button_learn'].visible = true;
		this.meshNodes['button_learn'].scale.set(1, 1, 10);
		this.meshNodes['button_see_again'].material = this.mats['button_see'];
		this.meshNodes['button_see_again'].scale.set(1, 1, 10);
		//
		
		
		this.doorNodes = [
			null,
			this.meshNodes['bevel_slide002'],
			this.meshNodes['bevel_slide01'],
			this.meshNodes['bevel_slide004'],
			this.meshNodes['bevel_slide003'],
			null
		];
		
		this.meshNodes['cube_outer'].renderOrder = 1;
		
		// gui for materials etc
		if (dat) {
			this.initGui();
		}
		
		// add to global cube root object
		this.cubeRoot.add(this.cubeScene);
		this.isCubeLoaded = true;
	}
	
	initGui() {
		this.gui = new dat.GUI();
		this.gui.close();
		
		var matGuis = {
			frame_inner: { folder: this.gui.addFolder('frame_inner'), mat: this.mats['frame_inner'] },
			frame_inner_lessref: {
				folder: this.gui.addFolder('frame_inner_lessref'),
				mat: this.mats['frame_inner_lessref']
			},
			metal_panel: { folder: this.gui.addFolder('metal_panel'), mat: this.mats['metal_panel'] },
			metal_panel1: { folder: this.gui.addFolder('metal_panel1'), mat: this.mats['metal_panel1'] },
			metal_panel2: { folder: this.gui.addFolder('metal_panel2'), mat: this.mats['metal_panel2'] },
			metal_panel3: { folder: this.gui.addFolder('metal_panel3'), mat: this.mats['metal_panel3'] },
			metal_panel4: { folder: this.gui.addFolder('metal_panel4'), mat: this.mats['metal_panel4'] },
			chrome: { folder: this.gui.addFolder('chrome'), mat: this.mats['chrome'] },
			frame: { folder: this.gui.addFolder('frame'), mat: this.mats['frame'] },
			glass: { folder: this.gui.addFolder('glass'), mat: this.mats['glass'] },
			windows: { folder: this.gui.addFolder('windows'), mat: this.mats['windows'] },
			text: { folder: this.gui.addFolder('text'), mat: this.mats['text'] },
			app_sides: { folder: this.gui.addFolder('app_sides'), mat: this.mats['app_sides'] },
			apps_back: { folder: this.gui.addFolder('apps_back'), mat: this.mats['apps_back'] },
			app_trans: { folder: this.gui.addFolder('app_trans'), mat: this.mats['app_trans'] },
			black: { folder: this.gui.addFolder('black'), mat: this.mats['black'] },
			black_flat: { folder: this.gui.addFolder('black_flat'), mat: this.mats['black_flat'] },
			button_learn: { folder: this.gui.addFolder('button_learn'), mat: this.mats['button_learn'] },
			button_see: { folder: this.gui.addFolder('button_see'), mat: this.mats['button_see'] }
		};
		
		var matTweaks = {
			color: 0xffffff,
			emissive: 0xffffff,
			emissiveIntensity: 10,
			metalness: 1,
			roughness: 0.1,
			envMapIntensity: 0.2
		};
		
		function makeChangeFcn(name, parm) {
			return (val) => {
				matGuis[name].mat[parm] = val;
			};
		}
		
		function makeChangeFcnSet(name, parm) {
			return (val) => {
				matGuis[name].mat[parm].set(val);
			};
		}
		
		for (var name in matGuis) {
			var obj = Object.assign({}, matTweaks);
			for (var prop in obj) {
				if (obj.hasOwnProperty(prop)) {
					let mat = matGuis[name].mat;
					if (mat[prop].isColor) {
						obj[prop] = mat[prop].getHex();
					} else {
						obj[prop] = mat[prop];
					}
				}
			}
			
			matGuis[name].folder.addColor(obj, 'color').onChange(makeChangeFcnSet(name, 'color'));
			matGuis[name].folder.addColor(obj, 'emissive').onChange(makeChangeFcnSet(name, 'emissive'));
			matGuis[name].folder.add(obj, 'emissiveIntensity').onChange(makeChangeFcn(name, 'emissiveIntensity'));
			matGuis[name].folder.add(obj, 'metalness').onChange(makeChangeFcn(name, 'metalness'));
			matGuis[name].folder.add(obj, 'roughness').onChange(makeChangeFcn(name, 'roughness'));
			matGuis[name].folder.add(obj, 'envMapIntensity').onChange(makeChangeFcn(name, 'envMapIntensity'));
		}
		
		this.gui.add(this.params, 'exposure');
		this.gui.add(this.params, 'useSSAA').listen();
		this.gui.add(this.params, 'doorSpeed').onChange((val) => {
			// this.mixer.timeScale = val;
		});
		this.params.forceOpen = () => {
			// force open
			this.openDoor(1);
			this.openDoor(2);
			this.openDoor(3);
			this.openDoor(4);
			this.doorNodes[1].visible = false;
			this.doorNodes[2].visible = false;
			this.doorNodes[3].visible = false;
			this.doorNodes[4].visible = false;
			this.faceOpen[1] = this.faceOpen[2] = this.faceOpen[3] = this.faceOpen[4] = true;
			this.setLightTheme();
		};
		this.gui.add(this.params, 'forceOpen');
		
		// cube light
		this.gui.addColor(this.params.int_light, 'color').onChange((val) => {
			this.pointLight.color.set(val);
		});
		this.gui.add(this.params.int_light, 'intensity').onChange((val) => {
			this.pointLight.intensity = val;
		});
		this.gui.add(this.params.int_light, 'decay').onChange((val) => {
			this.pointLight.decay = val;
		});
		
		//
		this.gui.add(this.params, 'disableAutoRot');
		this.gui.add(this.params, 'rotateSpeed').onChange((val) => {
			this.controls.autoRotateSpeed = val;
		});
	}
	
	openDoor(num, onComplete) {
		let node = this.doorNodes[num];
		let but = this.meshNodes['button_learn'];
		
		if (this.faceOpen[num]) {
			if (onComplete) {
				onComplete.call(this);
			}
			return;
		}
		
		var vec3 = this.rotateToFacePanel(0, 0.05, 0);
		this.enableClip(true);
		// door sliding inward, before pocketing
		TweenLite.to(node.position, 1.5, {
			x: vec3.x, y: vec3.y, z: vec3.z,
			ease: Power1.easeInOut
		});
		// door pocketing
		vec3 = this.rotateToFacePanel(2.5, 0, 0);
		TweenLite.to(node.position, (num === 1) ? 1.5 : 0.75, {
			x: vec3.x, y: vec3.y, z: vec3.z,
			delay: 1.5,
			ease: Power1.easeInOut
		});
		// queue up clipping
		TweenLite.to({ v: 0 }, node.visible ? 2.5 : 2.5, {
			v: 1,
			callbackScope: this,
			onComplete: () => {
				this.doorOpening = false;
				this.doorNodes[num].visible = false;
				this.enableClip(false);
				if (onComplete) {
					onComplete.call(this);
				}
			}
		});
		// fake shadow effect by blowing out envmap
		// var mat = this.mats['metal_panel' + num];
		// TweenLite.to(mat, 0.75, {
		// 	envMapIntensity: -6,
		// 	ease: Power4.easeInOut
		// });
		// depress button
		var mat = this.mats['button_learn'];
		TweenLite.to(but.position, 0.2, { x: 0.75 });
		TweenLite.to(mat, 0.2, { opacity: 0.33 });
		
		this.doorOpening = true;
	}
	
	// mesh nodes inside the cube need to be identified and animated
	collectMeshNodes() {
		// collect meshes from scene hierarchy for convenience
		var self = this;
		this.cubeScene.traverse((obj) => {
			if (obj.isMesh) {
				let name = obj.name || obj.parent.name;
				// use parent name if nec.
				obj.name = name;
				self.meshNodes[name] = obj;
			}
		});
	}
	
	convertMatToStd(mat) {
		var newMat = new THREE.MeshStandardMaterial({
			color: mat.color,
			map: mat.map,
			opacity: mat.opacity,
			transparent: mat.transparent,
			name: mat.name,
			metalness: 0,
			roughness: 0
		});
		this.remapMat[mat.name] = newMat;
		return newMat;
	}
	
	initMaterials() {
		this.remapMat = {};
		this.mats = {
			'frame_inner': this.convertMatToStd(this.cubeDAE.library.materials['frame_inner_sh-material'].build),
			'metal_panel': this.convertMatToStd(this.cubeDAE.library.materials['metal_panel_sh-material'].build),
			'metal_panel1': this.convertMatToStd(this.cubeDAE.library.materials['metal_panel_02-material'].build),
			'metal_panel2': this.convertMatToStd(this.cubeDAE.library.materials['metal_panel_01-material'].build),
			'metal_panel3': this.convertMatToStd(this.cubeDAE.library.materials['metal_panel_04-material'].build),
			'metal_panel4': this.convertMatToStd(this.cubeDAE.library.materials['metal_panel_03-material'].build),
			'chrome': this.convertMatToStd(this.cubeDAE.library.materials['chrome_sh-material'].build),
			'frame': this.convertMatToStd(this.cubeDAE.library.materials['frame_sh-material'].build),
			'glass': this.convertMatToStd(this.cubeDAE.library.materials['glass_sh-material'].build),
			'windows': this.convertMatToStd(this.cubeDAE.library.materials['windows_sh-material'].build),
			'text': this.convertMatToStd(this.cubeDAE.library.materials['text_sh-material'].build),
			'app_sides': this.convertMatToStd(this.cubeDAE.library.materials['app_sides-material'].build),
			'apps_back': this.convertMatToStd(this.cubeDAE.library.materials['apps_back_sh-material'].build),
			'app_trans': this.convertMatToStd(this.cubeDAE.library.materials['app_trans-material'].build),
			'black': this.convertMatToStd(this.cubeDAE.library.materials['black_sh-material'].build),
			'black_flat': this.convertMatToStd(this.cubeDAE.library.materials['black_flat_sh-material'].build),
			'button_learn': this.convertMatToStd(this.cubeDAE.library.materials['button_learnmore_sh-material'].build),
			'frame_inner_lessref': this.convertMatToStd(this.cubeDAE.library.materials['frame_inner_lessref_sh-material'].build)
		};
		
		// this.mats['glass'].color.set(0x000011);
		this.mats['glass'].envMapIntensity = 40;
		this.mats['glass'].opacity = 0.3;
		this.mats['glass'].depthWrite = false;
		// this.mats['glass'].metalness = 0;
		// this.mats['glass'].roughness = 0.05;
		
		
		this.mats['metal_panel'].color.set(0x222626);
		this.mats['metal_panel'].side = THREE.DoubleSide;
		this.mats['metal_panel'].metalness = 1;
		this.mats['metal_panel'].roughness = 0.35;
		this.mats['metal_panel'].envMapIntensity = 14;
		
		this.mats['metal_panel1'].color.set(0x222626);
		this.mats['metal_panel1'].side = THREE.DoubleSide;
		this.mats['metal_panel1'].metalness = 1;
		this.mats['metal_panel1'].roughness = 0.35;
		this.mats['metal_panel1'].envMapIntensity = 14;
		
		this.mats['metal_panel2'].color.set(0x222626);
		this.mats['metal_panel2'].side = THREE.DoubleSide;
		this.mats['metal_panel2'].metalness = 1;
		this.mats['metal_panel2'].roughness = 0.35;
		this.mats['metal_panel2'].envMapIntensity = 14;
		
		this.mats['metal_panel3'].color.set(0x222626);
		this.mats['metal_panel3'].side = THREE.DoubleSide;
		this.mats['metal_panel3'].metalness = 1;
		this.mats['metal_panel3'].roughness = 0.35;
		this.mats['metal_panel3'].envMapIntensity = 14;
		
		this.mats['metal_panel4'].color.set(0x222626);
		this.mats['metal_panel4'].side = THREE.DoubleSide;
		this.mats['metal_panel4'].metalness = 1;
		this.mats['metal_panel4'].roughness = 0.35;
		this.mats['metal_panel4'].envMapIntensity = 14;
		
		this.mats['frame'].color.set(0x040505);//0x010202);
		this.mats['frame'].metalness = 0.5;
		this.mats['frame'].roughness = 0.5;
		this.mats['frame'].envMapIntensity = 7;
		
		this.mats['chrome'].color.set(0x888888);
		this.mats['chrome'].metalness = 1;
		this.mats['chrome'].roughness = 0.2;
		this.mats['chrome'].envMapIntensity = 14;
		
		this.textInfo = {
			emissiveIntensity: 10,
			metalness: 1.5,
			roughness: 0.1,
			envMapIntensity: 10,
			delay: introInfo.flipDelay
		};
		
		this.mats['text'].color.set(0x0);
		this.mats['text'].emissive.set(0x0);
		this.mats['text'].emissiveIntensity = 0;
		this.mats['text'].metalness = 0;
		this.mats['text'].roughness = 0;
		this.mats['text'].envMapIntensity = 300;
		
		this.mats['black'].metalness = 1;
		this.mats['black'].roughness = 0.35;
		this.mats['black'].envMapIntensity = 7;
		
		this.mats['black_flat'].metalness = 0;
		this.mats['black_flat'].roughness = 0;//0.27;
		
		this.mats['button_learn'].transparent = true;
		this.mats['button_learn'].metalness = 0.5;
		this.mats['button_learn'].roughness = 0.3;
		this.mats['button_learn'].map.minFilter = THREE.NearestFilter;
		this.mats['button_learn'].map.magFilter = THREE.NearestFilter;
		// ugly hack to update image settings after async tex load from loader
		let self = this;
		setTimeout(() => {
			self.mats['button_learn'].map.needsUpdate = true;
		}, 4000);
		
		
		// make copy of learn button for see button to make it transparent
		this.mats['button_see'] = this.mats['button_learn'].clone();
		this.mats['button_see'].transparent = true;
		this.mats['button_see'].depthWrite = false;
		this.mats['button_see'].emissive.set(0xffffff);
		this.mats['button_see'].emissiveIntensity = 10000;
		// this.mats['button_see'].opacity = 0.8;
		
		// this.mats['windows'].transparent = true;
		this.mats['windows'].roughness = 1;
		
		// this.mats['app_sides'].transparent = true;
		// this.mats['app_sides'].opacity = 1;
		this.mats['app_sides'].color.set(0x070707);
		this.mats['app_sides'].emissiveIntensity = 1;
		this.mats['app_sides'].envMapIntensity = 1;
		this.mats['app_sides'].metalness = 0;
		this.mats['app_sides'].roughness = 1;
		this.mats['app_sides'].flatShading = true;
		
		this.mats['apps_back'].opacity = 1;
		this.mats['apps_back'].color.set(0x000000);
		this.mats['apps_back'].emissiveIntensity = 1;
		this.mats['apps_back'].envMapIntensity = 1;
		this.mats['apps_back'].metalness = 0;
		this.mats['apps_back'].roughness = 1;
		
		this.mats['frame_inner_lessref'].color.set(0x0);
		this.mats['frame_inner_lessref'].metalness = 0;
		this.mats['frame_inner_lessref'].roughness = 1;
		
		this.mats['frame_inner'].roughness = 0.3;
		
		this.mats['app_trans'].transparent = true;
		this.mats['app_trans'].roughness = 1;
		
		
		// remap geo to new mat
		this.cubeScene.traverse((obj) => {
			if (obj.material) {
				if (obj.material instanceof Array) {
					for (var ii = 0; ii < obj.material.length; ii++) {
						obj.material[ii] = this.remapMat[obj.material[ii].name];
					}
				} else {
					obj.material = this.remapMat[obj.material.name];
				}
			}
		});
		
		
		// clipping
		this.clipPlanes = [
			new THREE.Plane(new THREE.Vector3(-1, 0, 0), 1)
		];
		this.mats['metal_panel1'].clippingPlanes = this.clipPlanes;
		this.mats['metal_panel2'].clippingPlanes = this.clipPlanes;
		this.mats['metal_panel3'].clippingPlanes = this.clipPlanes;
		this.mats['metal_panel4'].clippingPlanes = this.clipPlanes;
		this.mats['text'].clippingPlanes = this.clipPlanes;
		this.mats['chrome'].clippingPlanes = this.clipPlanes;
		this.mats['black'].clippingPlanes = this.clipPlanes;
		this.mats['button_learn'].clippingPlanes = this.clipPlanes;
		this.enableClip(false);
		// var helpers = new THREE.Group();
		// for (var ci = 0 ; ci < this.clipPlanes.length ; ci++) {
		// 	helpers.add( new THREE.PlaneHelper( this.clipPlanes[ ci ], 2, 0xff0000 ) );
		// }
		// this.scene.add( helpers );
		
	}
	
	enableClip(enable) {
		this.clipPlanes.forEach((obj) => {
			obj.constant = enable ? 1 : 10;
		});
	}
	
	onWindowResize() {
		if (this.container.clientWidth !== this.com3dW || this.container.clientHeight !== this.com3dH) {
			this.camera.aspect = this.container.clientWidth / this.container.clientHeight;
			this.camera.updateProjectionMatrix();
			
			this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
			this.composer.setSize(this.container.clientWidth, this.container.clientHeight);
			
			this.com3dW = this.container.clientWidth;
			this.com3dH = this.container.clientHeight;
		}
	}
	
	onMouseClick(event) {
		if (this.inTutorial) {
			this.closeTutorial();
			event.preventDefault();
			event.stopPropagation();
			return;
		}
		
		let evClick = (event.type === 'touchstart') ? event.changedTouches[0] : event;
		// debugger;
		// event.preventDefault();
		let mouse = {
			x: ((evClick.clientX - this.container.offsetLeft) / event.target.clientWidth) * 2 - 1,
			y: -((evClick.clientY - this.container.offsetTop) / event.target.clientHeight) * 2 + 1
		};
		if (this.category < 1 || this.category > 4) {
			return;
		}
		
		let rc = new THREE.Raycaster();
		rc.setFromCamera(mouse, this.camera);
		var intersects = rc.intersectObjects(this.doorNodes[this.category].children, true);
		//this.scene.add(new THREE.ArrowHelper(rc.ray.direction, rc.ray.origin, 6, new THREE.Color(0xff0000), 0.1, 0.1));
		// console.log(intersects, this.doorNodes[this.category].children);
		
		for (var idx in intersects) {
			let name = intersects[idx].object.name || intersects[idx].object.parent.name;
			if (name === 'button_learn') {
				this.props.setCatReq(this.category);
			}
		}
		
		// force stop autoplay
		// this.setAutoPlay(false);
		
		// force swipe msg off
		// this.showEl(this.domSwipe, false);
	}

	setAutoPlay(play) {
		this.controls.autoRotate = play;
		// this.showEl(this.domPlay, !play);
		if (this.autoPlay !== play) {
			this.autoPlay = play;
			this.props.setPlayState(play);
		}
	}
	
	showEl(el, show, slow) {
		show = !(show === false);
		el.classList.remove(show ? 'opacityOff' : 'opacityOn');
		el.classList.remove(show ? 'opacityOffSlow' : 'opacityOnSlow');
		if (slow) {
			el.classList.add(show ? 'opacityOnSlow' : 'opacityOffSlow');
		} else {
			el.classList.add(show ? 'opacityOn' : 'opacityOff');
		}
	}
	
	animate() {
		window.requestAnimationFrame(this.animate);
		this.render3D();
	}
	
	startIntro() {
		// var self = this;
		
		// intro setup (this is called one time)
		this.camera.position.copy(introInfo.posStart);
		
		this.showEl(this.container, true);
		this.showEl(this.domCopyMain, true, true);
		this.showEl(this.domCopyBasic, true, true);
		
		this.camera.position.copy(introInfo.posEnd1);
		this.camera.lookAt(posZero);
		let mat = this.mats['text'];
		mat.color.set(0x0);
		mat.emissive.set(0x0);
		mat.emissiveIntensity = 0;
		mat.metalness = 0;
		mat.roughness = 0;
		mat.envMapIntensity = 300;
		TweenLite.to(mat, introInfo.flipTime, this.textInfo);
		TweenLite.to(mat.emissive, introInfo.flipTime, { r: 0.47, g: 0.47, b: 0.47, delay: introInfo.flipDelay });
		
		this.pointLight.position.set(-4, 3, 0);
		this.pointLight.intensity = 200;
		this.pointLight.decay = 0.05;
		this.pointLight.color.set(0xffffff);
		this.cubeRoot.rotation.set(0, introInfo.cubeAngEnd, 0);
		
		// "shimmer" by moving pointlight across the surface
		let tl = TweenLite.to(this.pointLight.position, introInfo.introTime + introInfo.flipDelay, {
			x: 4,
			ease: Power1.easeInOut,
			callbackScope: this,
			onUpdate: () => {
				this.pointLight.position.z = Math.sin(tl.ratio) * -1.9;
			},
			onComplete: () => {
				// time to flip everything toward its final interactive state
				this.showEl(this.domCopyMain, false, true);
				this.showEl(this.domCopyBasic, false, true);
				
				// cube's final orientation after intro
				TweenLite.to(this.cubeRoot.rotation, introInfo.flipTime, {
					delay: introInfo.flipDelay,
					y: introInfo.cubeAngEnd + -RAD45
					// y: introInfo.cubeAngEnd + (this.props.theme === 0) ? 0 : -RAD45
				});
				// pointlight during flip
				TweenLite.to(this.pointLight, introInfo.flipTime, {
					intensity: this.props.theme === 1 ? this.params.int_light.intensity : this.params.int_light.intensityDark,
					decay: this.props.theme === 1 ? this.params.int_light.decay : this.params.int_light.decayDark,
					callbackScope: this,
					onComplete: () => {
						this.pointLight.position.set(0, 0, 0);
						this.pointLight.color.set(this.props.theme === 1 ? this.params.int_light.color : this.params.int_light.colorDark);
						// TweenLite.to(this.pointLight, 0.5, {  });
					}
				});
				// camera flips down to view cube from the side
				TweenLite.to(this.camera.position, introInfo.flipTime, {
					delay: introInfo.flipDelay,
					x: introInfo.posEnd2.x, y: introInfo.posEnd2.y, z: introInfo.posEnd2.z,
					callbackScope: this,
					onUpdate: () => {
						this.camera.lookAt(posZero);
					},
					onComplete: () => {
						// time to show "swipe" instruction vignette
						mat.envMapIntensity = 100;
						
						// setTimeout(() => {
						// 	self.showEl(self.domSwipe, true);
						// }, 3000);
						// setTimeout(() => {
						// 	self.showEl(self.domSwipe, false);
						// }, 13000);
						
						this.camera.position.copy(introInfo.posEnd2);
						this.category = 1;
						// this.params.useSSAA = !isDEBUG;
						// if (this.props.theme === 1 || isDEBUG) {
							this.endIntro();
						// } else {
						// 	this.showSwipe();
						// }
					}
				});
			}
		});
	}
	
	showSwipe() {
		let iconPx = {
			startX: 150,
			startY: 130
		};
		
		
		// show click
		this.domSwipeIcon.style.left = 'calc(50% + ' + iconPx.startX + 'px)';
		this.domSwipeIcon.style.top = 'calc(50% + ' + iconPx.startY + 'px)';
		this.domSwipeIcon.style.display = 'block';
		this.showEl(this.domSwipeIcon, true);
		this.domSwipeIcon.classList.add('showClick');
		
		// show drag
		let tldrag = TweenLite.to(this.cubeRoot.rotation, introInfo.dragTime, {
			y: introInfo.cubeAngEnd + introInfo.cubeAngEndOffset,
			callbackScope: this,
			ease: Power1.easeInOut,
			delay: introInfo.dragDelay,
			onStart: () => {
				this.domSwipeIcon.classList.add('showDrag');
			},
			onUpdate: () => {
				let px = -175 * tldrag.ratio + iconPx.startX;
				this.domSwipeIcon.style.left = 'calc(50% + ' + px + 'px)';
				// this.domSwipeIcon.style.top = 'calc(50% + ' + iconPx.startY + 'px)';
			}
		});
		
		// show around
		let tl = TweenLite.to({ v: 0 }, introInfo.swipeTime, {
			v: 1,
			callbackScope: this,
			ease: Linear.easeInOut,
			delay: introInfo.dragDelay + introInfo.dragTime + introInfo.swipeDelay,
			onStart: () => {
				this.domSwipeIcon.classList.add('showExplore');
			},
			onUpdate: () => {
				this.cubeRoot.rotation.y = introInfo.cubeAngEnd + introInfo.cubeAngEndOffset + Math.sin(tl.ratio * TWO_PI) * 0.15;
				this.cubeRoot.rotation.x = -(Math.cos(tl.ratio * TWO_PI) * 0.3 - 0.3);
				let px = 33 * Math.sin(tl.ratio * TWO_PI) - 30;
				let py = 55 * Math.cos(tl.ratio * TWO_PI) - 186;
				this.domSwipeIcon.style.left = 'calc(50% + ' + px + 'px)';
				this.domSwipeIcon.style.top = 'calc(50% - ' + py + 'px)';
			},
			onComplete: this.endIntro
		});
	}
	
	endIntro() {
		let self = this;
		let cubeY = introInfo.cubeAngEnd + introInfo.cubeAngEndOffset;
		if (this.props.theme === 1) {
			cubeY += RAD45;
		}
		TweenLite.to(this.cubeRoot.rotation, 0.2, { y: cubeY, x: Math.PI });
		this.showEl(this.domSwipeIcon, false);
		setTimeout(() => {
			self.domSwipeIcon.style.display = 'none';
		}, 750);
		introInfo.finished = true;
		this.controls.enabled = true;
		this.controls.autoRotate = this.autoPlay;
		this.controls.enableRotate = !this.autoPlay;
		this.showEl(this.domMenu, true, true);
		this.showEl(this.domCopyMain, true, true);
		this.showEl(this.domPlay, true, false);
		// setTimeout(() => {
		// 	this.showEl(this.domPlay, false, true);
		// }, 10000);
	}
	
	render3D() {
		this.onWindowResize();
		
		if (!this.isCubeLoaded || !this.isEnvMapLoaded || !this.isGEnvMapLoaded) {
			// don't render 3d until all components have been loaded
			return;
		}
		
		if (this.stats) {
			this.stats.begin();
		}
		
		// currently aimed category
		let theta = this.controls.spherical.theta;
		// around cube sides +/-(0 <= x < 4)
		this.aimCatFine = ((theta - RAD45) % TWO_PI) / HALF_PI;
		// force positive
		if (this.aimCatFine < 0) {
			this.aimCatFine *= -1;
		} else {
			this.aimCatFine = 4 - this.aimCatFine;
		}
		// first side is index 1 since top is 0
		this.aimCatFine += 1;
		
		if (this.controls.enableRotate || this.autoPlay) {
			this.lastCategory = this.category;
			this.category = parseInt(this.aimCatFine);
		}
		this.props.setCatView(this.category);

		if (this.props.stopSignal === true) {
			if (this.isPlayingDetail) {
				this.cancelDetail();
			}
			this.props.setStopSignal(false);
		}
		
		//pub: this.showLearnMore(introInfo.finished && !this.faceOpen[this.category]);
		this.showLearnMore(!this.autoPlay && (introInfo.finished && !this.controls.externalInput));
		
		// assign env map once after all is loaded
		if (!this.isEnvMapAssigned) {
			// add environment cubemap to materials
			for (let name in this.mats) {
				if (this.mats.hasOwnProperty(name)) {
					if (name === 'glass') {
						this.mats[name].envMap = this.cubeEnvMapG;
					} else {
						this.mats[name].envMap = this.cubeEnvMap;
					}
				}
			}
			this.isEnvMapAssigned = true;
		}
		
		this.controls.update();
		// this.domDebug1.innerHTML = this.aimCatFine; //this.controls.spherical.theta;
		// this.domDebug2.innerHTML = this.aimCat;
		
		if (!introInfo.started) {
			this.startIntro();
			introInfo.started = true;
		}
		
		// light & shadow params
		this.renderer.toneMappingExposure = Math.pow(this.params.exposure, 5.0); // to allow for very bright scenes.
		this.renderer.shadowMap.enabled = this.params.shadows;
		if (this.params.shadows !== this.previousShadowMap) {
			this.previousShadowMap = this.params.shadows;
		}
		
		// auto play change
		if (this.autoPlay !== this.props.playStateReq) {
			// this.showEl(this.domPlay, false);
			this.props.setCatReq(-1);
			this.setAutoPlay(this.props.playStateReq);
		}
		
		// auto play trigger?
		if (this.autoPlay) {
			if (this.controls.autoRotate && (this.controls.getState() === this.controls.states.NONE) && !this.faceOpen[this.category]) {
				if (Math.abs(this.aimCatFine - this.category - 0.5) < 0.05) {
					this.clickCategory(true);
				}
			// } else if (this.controls.getState() === this.controls.states.ROTATE) {
				// this.autoPlay = false;
			} else if (this.props.theme === 1 && this.lastCategory === 3 && this.category === 4) {
				// this.autoPlay = false;
				this.setAutoPlay(false);
				this.props.setCatReq(5);
			}
			
		} else {
			
			// manual control
			if (this.props.categoryReq !== -1) {
				// first, close detail anim if avail
				this.cancelDetail();
				
				this.category = this.props.categoryReq;
				
				// manually hide hamb menu in case it's open
				this.domHMButton.checked = false;
				
				this.clickCategory(true);
				// reset catreq
				this.props.setCatReq(-1);
			}
		}
		
		// render frame
		if (this.params.useSSAA) {
			this.composer.render();
		} else {
			this.renderer.render(this.scene, this.camera);
		}
		
		if (this.stats) {
			this.stats.end();
		}
	}
	
	handlePlayToggle(event) {
		// don't pass through to other handlers
		event.preventDefault();
		event.stopPropagation();

		let evClick = (event.type === 'touchstart') ? event.changedTouches[0] : event;
		
		if (this.autoPlay) {
			if (evClick.offsetX >= 165) {
				// switch to manual
				this.inTutorial = true;
				this.domTutOver.style.display = 'block';
				this.domTutImg.style.display = 'block';
				this.showEl(this.domTutOver, true, true);
				this.showEl(this.domTutImg, true, true);
				
				this.controls.autoRotate = false;
				this.controls.externalInput = true;
				
				// sneakily flip cube to face PI
				TweenLite.to(this.controls.spherical, 0.5, {
					theta: this.getNearestTheta(1),
					phi: HALF_PI
				});
				
				this.props.setPlayState(false);
				// this.autoPlay = false;
				// this.setAutoPlay(false);
				
				// auto close tutorial...
				let self = this;
				setTimeout(() => {
					self.closeTutorial();
				}, this.params.tutorialTime * 1000);
			}

		} else {
			if (evClick.offsetX < 165) {
				// switch to auto
				document.location.reload();
			}
		}
		this.updateToggleButton();
	}
	
	updateToggleButton() {
		// update button state
		this.domPlay.classList.remove("autLight", "autDark", "manLight", "manDark");
		this.domPlay.classList.add((this.props.playState ? ((this.props.theme === 1) ? "autLight" : "autDark") : ((this.props.theme === 1) ? "manLight" : "manDark")));
	}
	
	closeTutorial() {
		if (this.inTutorial) {
			this.showEl(this.domTutOver, false, true);
			this.showEl(this.domTutImg, false, true);
			let self = this;
			setTimeout(() => {
				self.domTutOver.style.display = 'none';
				self.domTutImg.style.display = 'none';
			}, 500);
			this.controls.enableRotate = true;
			this.controls.externalInput = false;
			this.props.setCatReq(-1);
			this.inTutorial = false;
		}
	}
	
	getNearestTheta(cat) {
		let theta = this.controls.spherical.theta;
		
		let viewCat = parseInt(this.aimCatFine);
		var dist = viewCat - cat;			// 1->4 goes in negative theta direction (spinning cube ccw)
		let sign = dist < 0 ? -1 : 1;
		let absDist = Math.abs(dist);
		
		var targetTheta = theta + (distRots[absDist] * sign);
		
		let part = (this.aimCatFine % 1) - 0.5;
		targetTheta += part * HALF_PI;
		
		return targetTheta;
	}
	
	clickCategory(doLearnMore) {
		if (!introInfo.finished) {
			return;
		}
		let self = this;
		
		this.showEl(this.domPlay, false, true);
		
		var targetTheta = this.getNearestTheta(this.category);
		// console.log('S:'+this.controls.spherical.theta, 'C:'+targetTheta);
		
		this.trackPageView();
		
		var phi = HALF_PI;		// default for the four sides
		if (this.category === 5) {
			// look at bottom
			targetTheta = this.getNearestTheta(1);
			this.props.setDet(5);
			// this.domPlay.style.display = 'none';
			this.showEl(this.domPlay, false);
			// this.setAutoPlay(false);
			TweenLite.to(this.pointLight, 0.5, { intensity: 0 });
			TweenLite.to(this.controls.spherical, 0.5, { phi: Math.PI, delay: 0.5 });
			let self = this;
			setTimeout(() => {
				self.showEl(this.domCopyMain, true, true);
				self.showEl(this.domCopyTitle, true, true);
				self.showEl(this.domCopyBasic, true, true);
			}, 500);
			this.showEl(this.domCredit, true, true);
			
			
		} else if (this.category === 0) {
			// home - restart intro anim
			introInfo.started = false;
			introInfo.finished = false;
			this.showEl(this.domMenu, false);
			this.showEl(this.domCopy1, false);
			this.showEl(this.domCopy2, false);
			this.showEl(this.domCopy3, false);
			this.showEl(this.domCopy4, false);
			// this.showEl(this.domCopyMain, false);
			this.showEl(this.domCredit, false, true);
			
			
			// coming from top or bottom, just re-enable things
			// this.showEl(this.domCopyMain, true);
			// this.props.setBusyState(false);
			this.controls.externalInput = false;
			this.controls.reset();
			this.setupControls(this.controls);
			return;
			
		} else {
			this.props.setBusyState(true);
			this.showEl(this.domCopyMain, false);
			this.showEl(this.domCopyBasic, false);
			this.showEl(this.domCredit, false, true);
			// this.domPlay.style.display = 'block';
			TweenLite.to(this.pointLight, 0.5, {
				intensity: this.props.theme === 1 ? this.params.int_light.intensity : this.params.int_light.intensityDark,
				decay: this.props.theme === 1 ? this.params.int_light.decay : this.params.int_light.decayDark
			});
		}
		
		if (doLearnMore) {
			this.controls.enableRotate = false;
			this.controls.autoRotate = false;
			this.controls.externalInput = true;
		}
		
		TweenLite.to(this.controls.spherical, 0.5, {
			theta: targetTheta,
			phi: phi,
			callbackScope: this,
			onComplete: () => {
				if (doLearnMore) {
					if (this.category >= 1 && this.category <= 4) {
						this.showCube(false);
						setTimeout(() => {
							self.goLearnMore();
						}, 500);
					}
				}
			}
		});
	}
	
	goLearnMore() {
		if (this.category < 1 || this.category > 4) {
			return;
		}
		let self = this;
		
		this.showEl(this.domTimeline, true);
		
		// show Dom2D detail
		TweenLite.to({ v: 0 }, timings.win_fade, {
			v: 1,
			callbackScope: this,
			onComplete: () => {
				this.faceOpen[this.category] = true;
				
				this.props.setTheme(1);
				this.showEl(this.domMenu, false, true);
				this.showEl(this.domSubtitle, true, true);
				
				// setTimeout(() => {
				self.props.setDet(this.category);
				// }, timings.cube_del * 1000);
				
				this.isPlayingDetail = true;
			}
		});
		
		// detail end sequencing
		let detailTime = timings.detailTimes[this.category];
		
		// forcibly close detail after total delay
		let tl_whole = TweenLite.to({ v: 0 }, detailTime + timings.detailEndTime, {
			v: 1,
			callbackScope: this,
			ease: Linear.easeInOut,
			onUpdate: () => {
				this.domNeedle.style.left = (tl_whole.ratio * 295) + 'px';
			},
			onComplete: () => {
				if (this.isPlayingDetail) {
					this.cancelDetail();
				}
			}
		});
		this.detPending.push(tl_whole);
		
		// wait for detail to resolve, then show nav
		this.detPending.push(TweenLite.to({ v: 0 }, detailTime, {
			v: 1,
			callbackScope: this,
			onComplete: () => {
				this.showEl(this.domMenu, true, true);
				this.showEl(this.domSubtitle, false, true);
				this.props.setBusyState(false);
			}
		}));
	}
	
	isComplete() {
		return this.faceOpen[1] && this.faceOpen[2] && this.faceOpen[3] && this.faceOpen[4];
	}
	
	cancelDetail() {
		if (this.isPlayingDetail) {
			this.detPending.forEach((tl) => {
				tl.kill();
			});
			this.detPending.length = 0;
			this.props.setDet(0);
			this.showCube(true, this.nextSequence);
			// clear pends
			this.detPending.length = 0;
			this.isPlayingDetail = false;
			this.showEl(this.domMenu, true, true);
			this.showEl(this.domSubtitle, false, true);
			// in case they interrupted, the opendoor sequence gets skipped, so let's force the door to open state
			this.doorNodes[this.category].visible = false;
			
			this.showEl(this.domTimeline, false);
			this.showEl(this.domPlay, true, true);
			if (!this.isComplete()) {
				this.props.setTheme(0);
			}
		}
	}
	
	trackPageView() {
		// console.log({category: this.category, route: routes[this.category - 1]})
		ReactGA.pageview(`/${routes[this.category - 1]}`);
	}
	
	nextSequence() {
		if (this.category === 5) {
			// this.props.setBusyState(false);
			// this.controls.externalInput = false;
			// this.controls.enableRotate = true;
			// this.controls.minPolarAngle = 0.7;
			// this.controls.maxPolarAngle = Math.PI * 0.;
			// this.controls.autoRotate = true;
			// this.controls.externalInput = false;
		}
		if (this.category === 0) {
			// this.props.setBusyState(false);
			// this.controls.enableRotate = true;
			// this.controls.autoRotate = true;
			// this.controls.externalInput = false;
			return;
		}
		this.props.setBusyState(true);
		this.openDoor(this.category, () => {
			this.props.setBusyState(false);
			this.controls.enableRotate = !this.autoPlay;
			this.controls.autoRotate = this.autoPlay;
			this.controls.externalInput = false;
			this.controls.autoRotateSpeed = this.params.rotateSpeed * this.autoPlay ? 5 : 1;
			
			if (this.isComplete()) {
				// switch to light theme
				this.setLightTheme();
				// this.props.setDet(0);
				this.controls.autoRotateSpeed = this.params.rotateSpeed;
			}
		});
	}
	
	setLightTheme() {
		// tell CSS to switch theme
		this.props.setTheme(1);
		// this.props.setDet(0);
		// restore/guarantee blue light inside cube
		this.pointLight.position.set(0, 0, 0);
		this.pointLight.color.set(this.params.int_light.color);
		TweenLite.to(this.pointLight, 2.0, {
			intensity: this.params.int_light.intensity,
			decay: this.params.int_light.decay
		});
		// lighten frame slightly
		this.mats['frame'].metalness = 0.5;
		this.mats['frame'].roughness = 0.5;
		this.mats['frame'].color.set(0x0b0f0f);
		this.mats['frame'].side = THREE.FrontSide;
		
		this.updateToggleButton();
	}
	
	rotateToFacePanel(x, y, z) {
		return new THREE.Vector3(x, y, z).applyEuler(new THREE.Euler(0, 0, -HALF_PI * (this.category - 1)));
	}
	
	filter(fromVal, toVal) {
		fromVal = parseFloat(fromVal) || 0;
		toVal = parseFloat(toVal) || 0;
		const filt = 0.04;
		let d = toVal - fromVal;
		var v = d < 0 ? fromVal - filt : fromVal + filt;
		let min = Math.min(fromVal, toVal);
		let max = Math.max(fromVal, toVal);
		return Math.max(min, Math.min(max, v));
	}
	
	showLearnMore(show) {
		let deltaFine = this.aimCatFine - this.category;
		let dist = 1.0 - (Math.abs(deltaFine - 0.5) * 2);
		// console.log(dist);
		// note cat can be float for tweening button push
		let lm = this.meshNodes['button_learn'];
		let sa = this.meshNodes['button_see_again'];
		lm.visible = true;
		sa.visible = false;
		let open = this.faceOpen[this.category] || this.doorOpening;
		let mat = this.mats['button_learn'];
		let but = lm;
		but.visible = true;
		mat.emissive.set(open ? ((this.props.theme === 0) ? 0xffffff : 0) : 0);
		
		if (this.category < 1 || this.category > 4) {
			show = false;
		}
		
		if (show) {
			let door = this.doorNodes[this.category];
			if (lm.parent !== door) {
				door.add(lm);
			}
			mat.color.set(0xffffff);
			// don't move button until intro is finished (leaves visible fragments)
			if (introInfo.finished) {
				if (!this.doorOpening) {
					mat.opacity = Math.min(1, dist * 3);
					let ext = Math.min(0.89, 0.75 + 0.4 * dist);
					but.position.set(ext, 0, this.faceOpen[this.category] ? -0.5 : -0.3);
				}
				but.rotation.y = HALF_PI;
				// but.rotation.set(HALF_PI, HALF_PI * (this.category - 1), 0);
				// let moveVec = this.rotateToFacePanel(0, -0.75 + (-0.14 * dist), 0);
				// if (this.doorOpening) {
				// 	but.position.copy(this.doorNodes[this.category].position);
				// } else {
				// 	but.position.copy(moveVec);
				// 	but.position.z = open ? -0.5 : -0.3;
				// }
			}
			
		} else {
			// if (this.category < 1 || this.category > 4 || this.faceOpen[this.category]) {
				but.visible = false;
			// }
		}
		
		// copy text syncs with buttons
		// let nextFace = this.category + ((deltaFine < 0.5) ? -1 : 1);
		// if (nextFace === 0) {
		// 	nextFace = 4;
		// } else if (nextFace === 5) {
		// 	nextFace = 1;
		// }
		// let openNext = this.faceOpen[nextFace];
		let opac = Math.min(1, Math.sin(dist) * 2);
		if (!introInfo.finished) {
			opac = 1;
		} else if (this.category === 5) {
			opac = 1;
		}
		
		if (open && !this.isPlayingDetail) {
			this.domCopyMain.style.opacity = 1;
		// 	// default copy
		// 	// let showMain = (this.category === 5) || (this.controls.getState() === this.controls.states.ROTATE) || !introInfo.finished;
		// 	this.domCopyBasic.style.opacity = this.filter(this.domCopyBasic.style.opacity, opac); //showMain ? opac : 0);
		} else if (this.category === 5 || !introInfo.finished) {
			this.domCopyBasic.style.opacity = null;
			this.domCopyMain.style.opacity = null;
			this.domCopyTitle.style.opacity = null;
		} else {
			this.domCopyBasic.style.opacity = 0;
			this.domCopyMain.style.opacity = 0;
			this.domCopyTitle.style.opacity = 0;
		}
		// display: none required to allow the top/visible learnmore button to receive clicks
		if (this.category === 1 && open) {
			this.domCopy1.style.display = 'block';
			this.domCopy1.style.opacity = this.filter(this.domCopy1.style.opacity, opac);
			this.domCopyTitle.style.opacity = this.domCopy1.style.opacity;
		} else {
			this.domCopy1.style.display = 'none';
		}
		if (this.category === 2 && open) {
			this.domCopy2.style.display = 'block';
			this.domCopy2.style.opacity = this.filter(this.domCopy2.style.opacity, opac);
			this.domCopyTitle.style.opacity = this.domCopy2.style.opacity;
		} else {
			this.domCopy2.style.display = 'none';
		}
		if (this.category === 3 && open) {
			this.domCopy3.style.display = 'block';
			this.domCopy3.style.opacity = this.filter(this.domCopy3.style.opacity, opac);
			this.domCopyTitle.style.opacity = this.domCopy3.style.opacity;
		} else {
			this.domCopy3.style.display = 'none';
		}
		if (this.category === 4 && open) {
			this.domCopy4.style.display = 'block';
			this.domCopy4.style.opacity = this.filter(this.domCopy4.style.opacity, opac);
			this.domCopyTitle.style.opacity = this.domCopy4.style.opacity;
		} else {
			this.domCopy4.style.display = 'none';
		}
	}
	
	showCube(show, onComplete) {
		var self = this;
		// this.params.useSSAA = false;
		TweenLite.to(this.mats['windows'], timings.win_fade, {
			opacity: show ? 1 : 0,
			delay: show ? 0 : timings.learn_del,
			ease: TweenLite.easeInOut
		});
		TweenLite.to(this.mats['app_sides'], timings.win_fade, {
			opacity: show ? 1 : 0,
			delay: show ? 0 : timings.learn_del,
			ease: TweenLite.easeInOut
		});
		TweenLite.to(this.mats['apps_back'], timings.win_fade, {
			opacity: show ? 1 : 0,
			delay: show ? 0 : timings.learn_del,
			ease: TweenLite.easeInOut
		});
		TweenLite.to(this.cubeRoot.position, timings.cube_trans1, {
			z: show ? 0 : -7,//-5.5,
			// delay: show ? 0 : timings.cube_del,
			ease: Power1.easeIn
		});
		setTimeout(() => {
			self.showEl(this.container, show);
		}, show ? 0 : (timings.cube_del * 1000));
		TweenLite.to({ v: 0 }, (show ? 0 : timings.cube_del) + timings.cube_trans1 + 0.65, {
			v: 1,
			onComplete: () => {
				// if (show) {
				// 	this.showEl(this.domCopyMain, true);
				// }
				if (onComplete) {
					onComplete.call(this);
				}
			},
			callbackScope: this
		});
		
		this.showEl(this.domCopyMain, show, true);
	}
	
	render() {
		return (
			<div id="component3D" className={"opacityOff"}>
				<img id={"swipeIcon"} className={"swipeIcon opacityOff"} src={swipeIcon}/>
				{/*<div id="debug1"/>*/}
				{/*<div id="debug2"/>*/}
			</div>
		)
	}
}

export default Dom3D;
