import React from 'react';
import './App.css';
import './Footer.css';

class Footer extends React.Component {
	render() {
		return (
			<div className="nFooter">
				{this.props.notice}
				<div id={"nCredit"} className={"nCredit opacityOff"}>
					{this.props.credit}
				</div>
				<div id={"timeline-wrapper"} className={"timeline-wrapper opacityOff"}>
					<img className={"timeline"} />
					<img id={"needle"} className={"needle"} />
					<img className={"butStop"} onClick={() => {this.props.stopSignal(true);}} />
				</div>
			</div>
		)
	}
}

export default Footer;
