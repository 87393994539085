import React from 'react';
import './App.css';
import './Dom2D.css';

import content_PI_bg from './assets/layout_pi_bg.png';
import content_PI_o1bg from './assets/layout_pi_o1bg.png';
import content_PI_o1content1 from './assets/layout_pi_o1content1.png';
import content_PI_o1content2 from './assets/layout_pi_o1content2.png';
import content_PI_o2content1 from './assets/layout_pi_o2content1.png';
import content_PI_o2content2 from './assets/layout_pi_o2content2.png';
import content_PI_o3bg from './assets/layout_pi_o3bg.png';
import content_PI_o3content from './assets/layout_pi_o3content.png';
import content_PI_o3scrollcontent from './assets/layout_pi_o3scrollcontent.png';
import content_PI_o4content1 from './assets/layout_pi_o4content1.png';
import content_PI_o4content2 from './assets/layout_pi_o4content2.png';
import content_PI_copy1 from './assets/layout_pi_copy1.png';
import content_PI_copy2 from './assets/layout_pi_copy2.png';
import content_PI_copy3 from './assets/layout_pi_copy3.png';
import content_PI_tab1 from './assets/layout_pi_tab1.png';
import content_PI_tab2 from './assets/layout_pi_tab2.png';
import content_PI_tab3 from './assets/layout_pi_tab3.png';

import content_NN_bg from './assets/layout_nn_bg.png';
import content_NN_maincopybg1 from './assets/layout_nn_o1content1bg.png';
import content_NN_maincopy1_stroke from './assets/layout_nn_o1stroke1.png';
import content_NN_maincopy1 from './assets/layout_nn_o1content1.png';
import content_NN_maincopybg2 from './assets/layout_nn_o1content2bg.png';
import content_NN_maincopy2_stroke from './assets/layout_nn_o1stroke2.png';
import content_NN_maincopy2 from './assets/layout_nn_o1content2.png';
import content_NN_navmainbg from './assets/layout_nn_navbg.png';
import content_NN_navmain1 from './assets/layout_nn_nav1.png';
import content_NN_navmain2 from './assets/layout_nn_nav2.png';
import content_NN_navmain3 from './assets/layout_nn_nav3.png';
import content_NN_navsub1 from './assets/layout_nn_subnav1.png';
import content_NN_navsub2 from './assets/layout_nn_subnav2.png';
import content_NN_subcopy1 from './assets/layout_nn_o2content1.png';
import content_NN_subcopy2 from './assets/layout_nn_o2content2.png';

import content_CDM_bg from './assets/layout_cdm_bg.png';
import content_CDM_bg2 from './assets/layout_cdm_bg2.png';
import content_CDM_menu1 from './assets/layout_cdm_activemenu1.png';
import content_CDM_menu2 from './assets/layout_cdm_activemenu2.png';
import content_CDM_main1 from './assets/layout_cdm_inittab1.png';
import content_CDM_main2 from './assets/layout_cdm_inittab2.png';
import content_CDM_main3 from './assets/layout_cdm_circles.png';
import content_CDM_main4 from './assets/layout_cdm_carouselbg.png';
import content_CDM_carousel1 from './assets/layout_cdm_carousel1.png';
import content_CDM_carousel2 from './assets/layout_cdm_carousel2.png';

import content_NIM_bg from './assets/layout_nim_bg.png';
import content_NIM_sidebarbg from './assets/layout_nim_sidebarbg.png';
import content_NIM_sidebarcol1 from './assets/layout_nim_sidebarcolor1.png';
import content_NIM_sidebarcol2 from './assets/layout_nim_sidebarcolor2.png';
import content_NIM_sidebarcol3 from './assets/layout_nim_sidebarcolor3.png';
import content_NIM_sidebarcol4 from './assets/layout_nim_sidebarcolor4.png';
import content_NIM_matrixbg from './assets/layout_nim_matrixbg.png';
import content_NIM_matrix1 from './assets/layout_nim_matrix1.png';
import content_NIM_matrix2 from './assets/layout_nim_matrix2.png';
import content_NIM_matrix3 from './assets/layout_nim_matrix3.png';
import content_NIM_matrix4 from './assets/layout_nim_matrix4.png';
import content_NIM_matrix4_1 from './assets/layout_nim_matrix4_1.png';
import content_NIM_matrix4_2 from './assets/layout_nim_matrix4_2.png';
import content_NIM_matrix4_boxes from './assets/layout_nim_matrix4_boxes.png';
import content_NIM_matrix5 from './assets/layout_nim_matrix5.png';
import content_NIM_matrix6 from './assets/layout_nim_matrix6.png';
import content_NIM_carouselbg from './assets/layout_nim_carouselbg.png';
import content_NIM_carousel1 from './assets/layout_nim_carousel1.png';
import content_NIM_carousel2 from './assets/layout_nim_carousel2.png';




class Dom2D extends React.Component {
	
	constructor(props) {
		super(props);
		this.detail = this.props.detail;
		this.showDetail(0);

        this.class1 = "component2D";
        this.class1_1 = "content_PI_bg";
        this.class1_t_1 = "content_PI_tab1";
        this.class1_t_2 = "content_PI_tab2";
        this.class1_t_3 = "content_PI_tab3";
        this.class1_1_1 = "content_PI_overlay1";
        this.class1_1_2 = "content_PI_overlay1_bg";
        this.class1_1_3 = "content_PI_overlay1_nohighlight";
        this.class1_1_4 = "content_PI_overlay1_highlight";
        this.class1_2_1 = "content_PI_overlay2";
        this.class1_2_2 = "content_PI_overlay2_bg";
        this.class1_2_3 = "content_PI_overlay2_nohighlight";
        this.class1_2_4 = "content_PI_overlay2_highlight";
        this.class1_3_1 = "content_PI_overlay3";
        this.class1_3_2 = "content_PI_overlay3_bg";
        this.class1_3_3 = "content_PI_overlay3_scroll";
        this.class1_3_3img = "content_PI_overlay3_scrollimg";
        this.class1_3_4 = "content_PI_overlay3_highlight";
        this.class1_4_1 = "content_PI_overlay4";
        this.class1_4_2 = "content_PI_overlay4_bg";
        this.class1_4_3 = "content_PI_overlay4_nohighlight";
        this.class1_4_4 = "content_PI_overlay4_highlight";
        this.class1_c_1 = "content_PI_copy1";
        this.class1_c_2 = "content_PI_copy2";
        this.class1_c_3 = "content_PI_copy3";

        this.class1 = "component2D";
        this.class2_1 = "content_NIM_bg";
        this.class2_2 = "content_NIM_sidebar";
        this.class2_2_1 = "content_NIM_sidebarimg";
        this.class2_2_2 = "content_NIM_sidebarcolor1";
        this.class2_2_3 = "content_NIM_sidebarcolor2";
        this.class2_2_4 = "content_NIM_sidebarcolor3";
        this.class2_2_5 = "content_NIM_sidebarcolor4";
        this.class2_3 = "content_NIM_matrix";
        this.class2_3_1 = "content_NIM_matrixbg";
        this.class2_3_2 = "content_NIM_matrix1img";
        this.class2_3_3 = "content_NIM_matrix2img";
        this.class2_3_4 = "content_NIM_matrix3img";
        this.class2_3_5 = "content_NIM_matrix4";
        this.class2_3_5_1 = "content_NIM_matrix4img1";
        this.class2_3_5_2 = "content_NIM_matrix4img2";
        this.class2_3_5_3 = "content_NIM_matrix4img3";
        this.class2_3_5_4 = "content_NIM_matrix4_sub1";
        this.class2_3_5_5 = "content_NIM_matrix4_sub2";
        this.class2_3_5_6 = "content_NIM_matrix4_boxes";
        this.class2_4 = "content_NIM_carousel";
        this.class2_4_1 = "content_NIM_carouselbg";
        this.class2_4_2 = "content_NIM_carouselimg1";
        this.class2_4_3 = "content_NIM_carouselimg2";

        this.class3_1 = "content_CDM_bg";
        this.class3_1_1 = "content_CDM_bgimg1";
        this.class3_1_2 = "content_CDM_bgimg2";
        this.class3_2 = "content_CDM_main";
        this.class3_2_1 = "content_CDM_mainimg1";
        this.class3_2_2 = "content_CDM_mainimg2";
        this.class3_2_3 = "content_CDM_mainimg3";
        this.class3_2_4 = "content_CDM_mainimg4";
        this.class3_3 = "content_CDM_carousel";
        this.class3_3_1 = "content_CDM_carouselimg1";
        this.class3_3_2 = "content_CDM_carouselimg2";
        this.class3_4 = "content_CDM_menu";
        this.class3_4_1 = "content_CDM_menuimg1";
        this.class3_4_2 = "content_CDM_menuimg2";

        this.class4_1 = "content_NN_bg";
        this.class4_2 = "content_NN_navmain";
        this.class4_2_1 = "content_NN_navmainbgimg";
        this.class4_2_2 = "content_NN_navmain1img";
        this.class4_2_3 = "content_NN_navmain2img";
        this.class4_2_4 = "content_NN_navmain3img";
        this.class4_2_5 = "content_NN_navsub";
        this.class4_2_5_1 = "content_NN_navsubimg1";
        this.class4_2_5_2 = "content_NN_navsubimg2";
        this.class4_3 = "content_NN_maincopy";
        this.class4_3_1 = "content_NN_maincopybg1img";
        this.class4_3_2 = "content_NN_maincopycontent1img";
        this.class4_3_2_s = "content_NN_maincopycontent1stroke";
        this.class4_3_3 = "content_NN_maincopybg2img";
        this.class4_3_4 = "content_NN_maincopycontent2img";
        this.class4_3_4_s = "content_NN_maincopycontent2stroke";
        this.class4_4 = "content_NN_sidecopy";

        this.copy1 = "detCopyText";
        this.copy2 = "detCopyText";
        this.copy3 = "detCopyText";
        this.copy4 = "detCopyText";

        this.imagecontainerscale = "2dimgscale";

        // copyText width starts at 850, mediaquery it to 350 on narrow, h2s need to be another div
	}
	
	showDetail(cat) {
		switch (cat) {
			case 1:
			   // PI
                this.imagecontainerscale = "imgscale1-animatein";
				this.class1 = "component2D-animatein";
				this.class1_1 = "content_PI_bg-animatein";
				this.class1_1_1 = "content_PI_overlay1-animatein";
				this.class1_1_2 = "content_PI_overlay1_bg-animatein";
				this.class1_1_3 = "content_PI_overlay1_nohighlight-animatein";
				this.class1_1_4 = "content_PI_overlay1_highlight-animatein";
				this.class1_2_1 = "content_PI_overlay2-animatein";
				this.class1_2_2 = "content_PI_overlay2_bg-animatein";
				this.class1_2_3 = "content_PI_overlay2_nohighlight-animatein";
				this.class1_2_4 = "content_PI_overlay2_highlight-animatein";
				this.class1_3_1 = "content_PI_overlay3-animatein";
				this.class1_3_2 = "content_PI_overlay3_bg-animatein";
				this.class1_3_3 = "content_PI_overlay3_scroll-animatein";
				this.class1_3_3img = "content_PI_overlay3_scrollimg-animatein";
				this.class1_3_4 = "content_PI_overlay3_highlight-animatein";
				this.class1_4_1 = "content_PI_overlay4-animatein";
				this.class1_4_2 = "content_PI_overlay4_bg-animatein";
				this.class1_4_3 = "content_PI_overlay4_nohighlight-animatein";
				this.class1_4_4 = "content_PI_overlay4_highlight-animatein";
				this.class1_c_1 = "content_PI_copy1-animatein";
				this.class1_c_2 = "content_PI_copy2-animatein";
				this.class1_c_3 = "content_PI_copy3-animatein";
				this.class1_t_1 = "content_PI_tab1-animatein";
				this.class1_t_2 = "content_PI_tab2-animatein";
				this.class1_t_3 = "content_PI_tab3-animatein";
				this.copy1 = "detCopyText1-animatein";
				break;

			case 2:
				//NIM
                this.imagecontainerscale = "imgscale2-animatein";
                this.class1 = "component2D-animatein";
                this.class2_1 = "content_NIM_bg-animatein";
                this.class2_2 = "content_NIM_sidebar-animatein";
                this.class2_2_1 = "content_NIM_sidebarimg-animatein";
                this.class2_2_2 = "content_NIM_sidebarcolor1-animatein";
                this.class2_2_3 = "content_NIM_sidebarcolor2-animatein";
                this.class2_2_4 = "content_NIM_sidebarcolor3-animatein";
                this.class2_2_5 = "content_NIM_sidebarcolor4-animatein";
                this.class2_3 = "content_NIM_matrix-animatein";
                this.class2_3_1 = "content_NIM_matrixbg-animatein";
                this.class2_3_2 = "content_NIM_matrix1img-animatein";
                this.class2_3_3 = "content_NIM_matrix2img-animatein";
                this.class2_3_4 = "content_NIM_matrix3img-animatein";
                this.class2_3_5 = "content_NIM_matrix4-animatein";
                this.class2_3_5_1 = "content_NIM_matrix4img1-animatein";
                this.class2_3_5_2 = "content_NIM_matrix4img2-animatein";
                this.class2_3_5_3 = "content_NIM_matrix4img3-animatein";
                this.class2_3_5_4 = "content_NIM_matrix4_sub1-animatein";
                this.class2_3_5_5 = "content_NIM_matrix4_sub2-animatein";
                this.class2_3_5_6 = "content_NIM_matrix4_boxes-animatein";
                this.class2_4 = "content_NIM_carousel-animatein";
                this.class2_4_1 = "content_NIM_carouselbg-animatein";
                this.class2_4_2 = "content_NIM_carouselimg1-animatein";
                this.class2_4_3 = "content_NIM_carouselimg2-animatein";
                this.copy2 = "detCopyText2-animatein";

                break;




            case 3:
                // CDM
                this.imagecontainerscale = "imgscale3-animatein";
                this.class1 = "component2D-animatein";
                this.class3_1 = "content_CDM_bg-animatein";
                this.class3_1_1 = "content_CDM_bgimg1-animatein";
                this.class3_1_2 = "content_CDM_bgimg2-animatein";
                this.class3_2 = "content_CDM_main-animatein";
                this.class3_2_1 = "content_CDM_mainimg1-animatein";
                this.class3_2_2 = "content_CDM_mainimg2-animatein";
                this.class3_2_3 = "content_CDM_mainimg3-animatein";
                this.class3_2_4 = "content_CDM_mainimg4-animatein";
                this.class3_3 = "content_CDM_carousel-animatein";
                this.class3_3_1 = "content_CDM_carouselimg1-animatein";
                this.class3_3_2 = "content_CDM_carouselimg2-animatein";
                this.class3_4 = "content_CDM_menu-animatein";
                this.class3_4_1 = "content_CDM_menuimg1-animatein";
                this.class3_4_2 = "content_CDM_menuimg2-animatein";
                this.copy3 = "detCopyText3-animatein";


                break;

			case 4:
			   // NN
                this.imagecontainerscale = "imgscale4-animatein";
				this.class1 = "component2D-animatein";
				this.class4_1 = "content_NN_bg-animatein";
				this.class4_2 = "content_NN_navmain-animatein";
				this.class4_2_1 = "content_NN_navmainbgimg-animatein";
				this.class4_2_2 = "content_NN_navmain1img-animatein";
				this.class4_2_3 = "content_NN_navmain2img-animatein";
				this.class4_2_4 = "content_NN_navmain3img-animatein";
				this.class4_2_5 = "content_NN_navsub-animatein";
                this.class4_2_5_1 = "content_NN_navsubimg1-animatein";
                this.class4_2_5_2 = "content_NN_navsubimg2-animatein";
				this.class4_3 = "content_NN_maincopy-animatein";
				this.class4_3_1 = "content_NN_maincopybg1img-animatein";
				this.class4_3_2 = "content_NN_maincopycontent1img-animatein";
				this.class4_3_2_s = "content_NN_maincopycontent1stroke-animatein";
				this.class4_3_3 = "content_NN_maincopybg2img-animatein";
				this.class4_3_4 = "content_NN_maincopycontent2img-animatein";
				this.class4_3_4_s = "content_NN_maincopycontent2stroke-animatein";
				this.class4_4 = "content_NN_sidecopy-animatein";
                this.class4_4_1 = "content_NN_sidecopy1img-animatein";
                this.class4_4_2 = "content_NN_sidecopy2img-animatein";
                this.copy4 = "detCopyText4-animatein";
				break;
			
			case 0:
			default:
				// CLEAR
                this.imagecontainerscale = "imgscale";

				this.class1 = "component2D-animateout";
				this.class1_1 = "content_PI_bg-animateout";
				this.class1_t_1 = "content_PI_tab1";
				this.class1_t_2 = "content_PI_tab2";
				this.class1_t_3 = "content_PI_tab3";
				this.class1_1_1 = "content_PI_overlay1";
				this.class1_1_2 = "content_PI_overlay1_bg";
				this.class1_1_3 = "content_PI_overlay1_nohighlight";
				this.class1_1_4 = "content_PI_overlay1_highlight";
				this.class1_2_1 = "content_PI_overlay2";
				this.class1_2_2 = "content_PI_overlay2_bg";
				this.class1_2_3 = "content_PI_overlay2_nohighlight";
				this.class1_2_4 = "content_PI_overlay2_highlight";
				this.class1_3_1 = "content_PI_overlay3";
				this.class1_3_2 = "content_PI_overlay3_bg";
				this.class1_3_3 = "content_PI_overlay3_scroll";
				this.class1_3_3img = "content_PI_overlay3_scrollimg";
				this.class1_3_4 = "content_PI_overlay3_highlight";
				this.class1_4_1 = "content_PI_overlay4";
				this.class1_4_2 = "content_PI_overlay4_bg";
				this.class1_4_3 = "content_PI_overlay4_nohighlight";
				this.class1_4_4 = "content_PI_overlay4_highlight";
				this.class1_c_1 = "content_PI_copy1";
				this.class1_c_2 = "content_PI_copy2";
				this.class1_c_3 = "content_PI_copy3";

                this.class1 = "component2D";
                this.class2_1 = "content_NIM_bg";
                this.class2_2 = "content_NIM_sidebar";
                this.class2_2_1 = "content_NIM_sidebarimg";
                this.class2_2_2 = "content_NIM_sidebarcolor1";
                this.class2_2_3 = "content_NIM_sidebarcolor2";
                this.class2_2_4 = "content_NIM_sidebarcolor3";
                this.class2_2_5 = "content_NIM_sidebarcolor4";
                this.class2_3 = "content_NIM_matrix";
                this.class2_3_1 = "content_NIM_matrixbg";
                this.class2_3_2 = "content_NIM_matrix1img";
                this.class2_3_3 = "content_NIM_matrix2img";
                this.class2_3_4 = "content_NIM_matrix3img";
                this.class2_3_5 = "content_NIM_matrix4";
                this.class2_3_5_1 = "content_NIM_matrix4img1";
                this.class2_3_5_2 = "content_NIM_matrix4img2";
                this.class2_3_5_3 = "content_NIM_matrix4img3";
                this.class2_3_5_4 = "content_NIM_matrix4_sub1";
                this.class2_3_5_5 = "content_NIM_matrix4_sub2";
                this.class2_3_5_6 = "content_NIM_matrix4_boxes";
                this.class2_4 = "content_NIM_carousel";
                this.class2_4_1 = "content_NIM_carouselbg";
                this.class2_4_2 = "content_NIM_carouselimg1";
                this.class2_4_3 = "content_NIM_carouselimg2";

                this.class3_1 = "content_CDM_bg";
                this.class3_1_1 = "content_CDM_bgimg1";
                this.class3_1_2 = "content_CDM_bgimg2";
                this.class3_2 = "content_CDM_main";
                this.class3_2_1 = "content_CDM_mainimg1";
                this.class3_2_2 = "content_CDM_mainimg2";
                this.class3_2_3 = "content_CDM_mainimg3";
                this.class3_2_4 = "content_CDM_mainimg4";
                this.class3_3 = "content_CDM_carousel";
                this.class3_3_1 = "content_CDM_carouselimg1";
                this.class3_3_2 = "content_CDM_carouselimg2";
                this.class3_4 = "content_CDM_menu";
                this.class3_4_1 = "content_CDM_menuimg1";
                this.class3_4_2 = "content_CDM_menuimg2";
				
				this.class4_1 = "content_NN_bg";
				this.class4_2 = "content_NN_navmain";
				this.class4_2_1 = "content_NN_navmainbgimg";
				this.class4_2_2 = "content_NN_navmain1img";
				this.class4_2_3 = "content_NN_navmain2img";
				this.class4_2_4 = "content_NN_navmain3img";
				this.class4_2_5 = "content_NN_navsub";
                this.class4_2_5_1 = "content_NN_navsubimg1";
                this.class4_2_5_2 = "content_NN_navsubimg2";
				this.class4_3 = "content_NN_maincopy";
				this.class4_3_1 = "content_NN_maincopybg1img";
				this.class4_3_2 = "content_NN_maincopycontent1img";
				this.class4_3_2_s = "content_NN_maincopycontent1stroke";
				this.class4_3_3 = "content_NN_maincopybg2img";
				this.class4_3_4 = "content_NN_maincopycontent2img";
				this.class4_3_4_s = "content_NN_maincopycontent2stroke";
				this.class4_4 = "content_NN_sidecopy";

                this.copy1 = "detCopyText";
                this.copy2 = "detCopyText";
                this.copy3 = "detCopyText";
                this.copy4 = "detCopyText";
				break;
		}
	}
	
	render() {
		
		if (this.props.detail !== this.detail) {
			this.showDetail(this.props.detail);
			this.detail = this.props.detail;
		}
		
		return (
			<div className={this.class1}>
				<div className={this.imagecontainerscale}>
				<div id="content_PI">
					<div className={this.class1_1}><img id="content_PI_bgimg" src={content_PI_bg}/></div>
					<div className={this.class1_t_1}><img id="content_PI_tab1img" src={content_PI_tab1}/></div>
					<div className={this.class1_t_2}><img id="content_PI_tab2img" src={content_PI_tab2}/></div>
					<div className={this.class1_t_3}><img id="content_PI_tab3img" src={content_PI_tab3}/></div>
					<div className={this.class1_1_1}>
						<div className={this.class1_1_2}><img id="content_PI_overlay1_bgimg" src={content_PI_o1bg}/></div>
						<div className={this.class1_1_3}><img id="content_PI_overlay1_nohighlightimg"
																		  src={content_PI_o1content1}/></div>
						<div className={this.class1_1_4}><img id="content_PI_overlay1_highlightimg"
																		  src={content_PI_o1content2}/></div>
					</div>
					<div className={this.class1_2_1}>
						<div className={this.class1_2_2}><img id="content_PI_overlay2_bgimg" src={content_PI_o1bg}/></div>
						<div className={this.class1_2_3}><img id="content_PI_overlay2_nohighlightimg"
																		  src={content_PI_o2content1}/></div>
						<div className={this.class1_2_4}><img id="content_PI_overlay2_highlightimg"
																		  src={content_PI_o2content2}/></div>
					</div>
					<div className={this.class1_3_1}>
						<div className={this.class1_3_2}><img id="content_PI_overlay3_bgimg" src={content_PI_o3bg}/></div>
						<div className={this.class1_3_3}><img className={this.class1_3_3img}
																		  src={content_PI_o3scrollcontent}/></div>
						<div className={this.class1_3_4}><img id="content_PI_overlay3_highlightimg"
																		  src={content_PI_o3content}/></div>
					</div>
					<div className={this.class1_4_1}>
						<div className={this.class1_4_2}><img id="content_PI_overlay4_bgimg" src={content_PI_o1bg}/></div>
						<div className={this.class1_4_3}><img id="content_PI_overlay4_nohighlightimg"
																		  src={content_PI_o4content1}/></div>
						<div className={this.class1_4_4}><img id="content_PI_overlay4_highlightimg"
																		  src={content_PI_o4content2}/></div>
					</div>
					<div className={this.class1_c_1}><img id="content_PI_copy1img" src={content_PI_copy1}/></div>
					<div className={this.class1_c_2}><img id="content_PI_copy2img" src={content_PI_copy2}/></div>
					<div className={this.class1_c_3}><img id="content_PI_copy3img" src={content_PI_copy3}/></div>
				</div>



				<div id="content_NIM">
					<img className={this.class2_1} src={content_NIM_bg}/>
					<div className={this.class2_2}>
                        <img className={this.class2_2_1} src={content_NIM_sidebarbg}/>
						<img className={this.class2_2_2} src={content_NIM_sidebarcol1}/>
                        <img className={this.class2_2_3} src={content_NIM_sidebarcol2}/>
                        <img className={this.class2_2_4} src={content_NIM_sidebarcol3}/>
                        <img className={this.class2_2_5} src={content_NIM_sidebarcol4}/>
					</div>
					<div className={this.class2_3}>
						<img className={this.class2_3_1} src={content_NIM_matrixbg}/>
						<img className={this.class2_3_2} src={content_NIM_matrix1}/>
                        <img className={this.class2_3_3} src={content_NIM_matrix2}/>
                        <img className={this.class2_3_4} src={content_NIM_matrix3}/>
                        <div className={this.class2_3_5}>
							<img className={this.class2_3_5_1} src={content_NIM_matrix4}/>
                            <img className={this.class2_3_5_2} src={content_NIM_matrix5}/>
                            <img className={this.class2_3_5_3} src={content_NIM_matrix6}/>
							<img className={this.class2_3_5_4} src={content_NIM_matrix4_1}/>
                            <img className={this.class2_3_5_5} src={content_NIM_matrix4_2}/>

						</div>
					</div>
					<div className={this.class2_4}>
						<img className={this.class2_4_1} src={content_NIM_carouselbg}/>
                        <img className={this.class2_4_2} src={content_NIM_carousel1}/>
						<img className={this.class2_4_3} src={content_NIM_carousel2}/>


					</div>
                    <img className={this.class2_3_5_6} src={content_NIM_matrix4_boxes}/>
				</div>



				<div id="content_CDM">
					<div className={this.class3_1}>
						<img className={this.class3_1_1} src={content_CDM_bg}/>
                        <img className={this.class3_1_2} src={content_CDM_bg2}/>
					</div>
					<div className={this.class3_2}>
						<img className={this.class3_2_1} src={content_CDM_main1}/>
                        <img className={this.class3_2_2} src={content_CDM_main2}/>
                        <img className={this.class3_2_3} src={content_CDM_main3}/>
                        <img className={this.class3_2_4} src={content_CDM_main4}/>
					</div>
                    <div className={this.class3_3}>
                        <img className={this.class3_3_1} src={content_CDM_carousel1}/>
                        <img className={this.class3_3_2} src={content_CDM_carousel2}/>
                    </div>
                    <div className={this.class3_4}>
                        <img className={this.class3_4_1} src={content_CDM_menu1}/>
                        <img className={this.class3_4_2} src={content_CDM_menu2}/>
                    </div>

				</div>
				<div id="content_NN">
					<div className={this.class4_1}><img id="content_NN_bgimg" src={content_NN_bg}/></div>
					<div className={this.class4_2}>
						<img className={this.class4_2_1} src={content_NN_navmainbg}/>
						<img className={this.class4_2_2} src={content_NN_navmain1}/>
						<img className={this.class4_2_3} src={content_NN_navmain2}/>
						<img className={this.class4_2_4} src={content_NN_navmain3}/>
					</div>
                    <div className={this.class4_2_5}>
                        <img className={this.class4_2_5_1} src={content_NN_navsub1}/>
                        <img className={this.class4_2_5_2} src={content_NN_navsub2}/>
                    </div>
					<div className={this.class4_3}>
						<img className={this.class4_3_1} src={content_NN_maincopybg1}/>
						<img className={this.class4_3_2} src={content_NN_maincopy1}/>
						<img className={this.class4_3_2_s} src={content_NN_maincopy1_stroke}/>
						<img className={this.class4_3_3} src={content_NN_maincopybg2}/>
						<img className={this.class4_3_4} src={content_NN_maincopy2}/>
						<img className={this.class4_3_4_s} src={content_NN_maincopy2_stroke}/>
					</div>
					<div className={this.class4_4}>
                        <img className={this.class4_4_1} src={content_NN_subcopy1}/>
                        <img className={this.class4_4_2} src={content_NN_subcopy2}/>
					</div>
				
				</div>
				</div>

                <div id="copyTextPI" className={this.copy1}>
                    <div className="copysubhead">TRANSFORMING YOUR TRANSACTION PROCESS</div>
                    Inventories are automatically & immediately constructed & easily filtered by governing law,
                    sector & geography. The Prevalence Feature calculates the occurrence of a clause within your
                    institution's data set to provide an immediate understanding of the "value" of a provision.
                </div>
                <div id="copyTextNIM" className={this.copy2}>
                    <div className="copysubhead">TRANSFORMING YOUR DATA ACCESS AND INSIGHTS</div>
                    The Nammu Inventory Matrix ("NIM") provides immediate access to key data points & relevant
                    document provisions from which they are drawn, as well as the ability to review provisions from
                    multiple transactions side by side without the need to search for those provisions manually. NIM
                    is easily filtered by governing law, sector & geography, and provides a roadmap highlighting the
                    omissions, anomalies & exceptions that may exist in your transactions & portfolios.
                </div>
                <div id="copyTextCDM" className={this.copy3}>
                    <div className="copysubhead">TRANSFORMING YOUR COMPLIANCE AND RISK ASSESSMENTS</div>
                    Core Document Metrics ("CDMs") provide immediate access to key metrics & relevant document
                    provisions from which they are drawn, as well as the ability to review relevant provisions from
                    multiple transactions side by side without the need to search for those provisions manually.
                    CDMs are easily filtered by governing law, sector & geography and provide a roadmap
                    highlighting the omissions, anomalies & exceptions that may exist in your transactions & portfolios.
                </div>
                <div id="copyTextNN" className={this.copy4}>
                    <div className="copysubhead">TRANSFORMING YOUR TRANSACTION EXPERIENCE</div>
                    Nammu Navigation is a unique, advanced method of navigating through complex documents in
                    a dynamic interactive single screen to view a single document, or a split screen to view
                    connected documents across a transaction. This feature provides a framework through which to
                    identify specific provisions, connections and omissions with defined terms and cross-references
                    within a document and between connected documents to be immediately available.
                </div>

			</div>

		)
	}
}

export default Dom2D;
