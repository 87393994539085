import React from 'react';
import './App.css';

class MenuItem extends React.Component {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}
	
	onClick(event) {
		this.props.callback(this.props.id);
	}
	
	render() {
		let classes = "nMenuItem " + ((this.props.theme === 0) ? "nMenuItemDark " : "nMenuItemLight ") + ((this.props.catView === parseInt(this.props.id)) ? "boldOn" : "boldOff");
		return (
			<li className={classes} onClick={this.onClick}>
				<p>{this.props.text}</p>
			</li>
		)
	}
}

class Menu extends React.Component {
	constructor(props) {
		super(props);
		this.menuItems = [];
		this.menuItems.push("HOME");
		this.menuItems.push("PROVISION INVENTORY");
		this.menuItems.push("NAMMU INVENTORY MATRIX");
		this.menuItems.push("CORE DOCUMENT METRICS");
		this.menuItems.push("NAMMU NAVIGATION");
		this.menuItems.push("CONTACT");
		this.handleChange = this.handleChange.bind(this);
		
	}
	
	handleChange(item) {
		if (!this.props.busyState) {
			var id = parseFloat(item);
			if (id >= 0 && id <= 5) {
				this.props.setCatReq(id);
			}
			// console.log(item);
		}
	}
	
	render() {
		return (
			<ul id="nMenu" className={"nMenu opacityOff disable-select"}>
				<MenuItem text={this.menuItems[0]}
							 callback={this.handleChange}
							 theme={this.props.theme}
							 catView={this.props.catView}
							 id='0'/>
				<MenuItem text={this.menuItems[1]}
							 callback={this.handleChange}
							 theme={this.props.theme}
							 catView={this.props.catView}
							 id='1'/>
				<MenuItem text={this.menuItems[2]}
							 callback={this.handleChange}
							 theme={this.props.theme}
							 catView={this.props.catView}
							 id='2'/>
				<MenuItem text={this.menuItems[3]}
							 callback={this.handleChange}
							 theme={this.props.theme}
							 catView={this.props.catView}
							 id='3'/>
				<MenuItem text={this.menuItems[4]}
							 callback={this.handleChange}
							 theme={this.props.theme}
							 catView={this.props.catView}
							 id='4'/>
				<MenuItem text={this.menuItems[5]}
							 callback={this.handleChange}
							 theme={this.props.theme}
							 catView={this.props.catView}
							 id='5'/>
			
			</ul>
		)
	}
}

export default Menu;
