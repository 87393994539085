import React, {useState} from 'react';
import logo from './assets/N21-badge-temp.png';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import Menu from './Menu';
import Dom3D from './Dom3D';
import Dom2D from './Dom2D';
import bar from './assets/bar.png';
import butWALight from './assets/WATCHAGAIN_black.png';
import butWADark from './assets/WATCHAGAIN_white.png';
import butSALight from './assets/SEE_AGAIN_black.png';
import butSADark from './assets/SEE_AGAIN_white.png';
import imgTutorial from './assets/tutorial.png';


function App() {
	
	const [categoryReq, setCatReq] = useState(-1);
	const [catView, setCatView] = useState(0);
	const [detail, setDetail] = useState(0);
	const [theme, setTheme] = useState(0);
	const [playState, setPlay] = useState(true);
	const [busyState, setBusy] = useState(false);
	const [stopSignal, setStopSignal] = useState(false);
	
	const catNames = [
		"",
		"PROVISION INVENTORY",
		"NAMMU INVENTORY MATRIX",
		"CORE DOCUMENT METRICS",
		"NAMMU NAVIGATION",
		""
	];
	
	return (
		<div className={theme === 0 ? "backdrop backdropDark" : "backdrop backdropLight"} style={{ overflow: "hidden" }}>
			<div className={theme === 0 ? "App AppDark" : "App AppLight"}>
				<Header title={""} logo={logo} setCatReq={setCatReq} detail={detail} catView={catView} catName={catNames[detail]}/>
				
				<Menu setCatReq={setCatReq} theme={theme} detail={detail} busyState={busyState} catView={catView}/>
				<Dom3D categoryReq={categoryReq} setCatReq={setCatReq} setDet={setDetail} setTheme={setTheme} theme={theme}
						 playStateReq={playState} setPlayState={setPlay} setBusyState={setBusy} setCatView={setCatView} playState={playState}
						 stopSignal={stopSignal} setStopSignal={setStopSignal}/>
				<Dom2D detail={detail}/>
				
				<div id="copyMain" className={"copy opacityOff disable-select " + (detail === 5 ? "contactPage" : "")}>
					<div id="copyTitle" className={"copyTitle"}>GOLDEN SOURCE DATA<br/>FOR SYNDICATED LOANS</div>
					<div className={"copyWrapper"}>
						<div id="copyTextBasic" className={"copyText disable-select"}>A digitization and analytics platform
							deconstructing complex interconnected documents across the finance ecosystem delivering operational
							efficiencies, generating new value and connecting the business of today to the digital world of
							tomorrow.
							<br/><img src={theme === 0 ? butWADark : butWALight} className={"copyButLM " + (detail === 5 ? "opacityOn" : "opacityOff")} onClick={(e) => { document.location.reload() }} alt=""/>
							<div className={"contactExtra " + (detail === 5 ? "opacityOn" : "opacityOff")}>
								Contact us to learn more about the platform and additional features.
							</div>
						</div>
						<div id="copyText1" className={"copyText disable-select"}>
							<img src={bar} alt="" className={"nBar"}/><br/>
							{catNames[1]}
							<br/><img src={playState ? "" : (theme === 0 ? butSADark : butSALight)} className={"copyButLM"} onClick={(e) => { setCatReq(1)} } alt=""/>
						</div>
						<div id="copyText2" className={"copyText disable-select"}>
							<img src={bar} alt="" className={"nBar"}/><br/>
							{catNames[2]}
							<br/><img src={playState ? "" : (theme === 0 ? butSADark : butSALight)} className={"copyButLM"} onClick={(e) => { setCatReq(2)} } alt=""/>
						</div>
						<div id="copyText3" className={"copyText disable-select"}>
							<img src={bar} alt="" className={"nBar"}/><br/>
							{catNames[3]}
							<br/><img src={playState ? "" : (theme === 0 ? butSADark : butSALight)} className={"copyButLM"} onClick={(e) => { setCatReq(3)} } alt=""/>
						</div>
						<div id="copyText4" className={"copyText disable-select"}>
							<img src={bar} alt="" className={"nBar"}/><br/>
							{catNames[4]}
							<br/><img src={playState ? "" : (theme === 0 ? butSADark : butSALight)} className={"copyButLM"} onClick={(e) => { setCatReq(4)} } alt=""/>
						</div>
					</div>
				
				</div>
				<div id={"play"} className={"resumeBtn"}/>
				<Footer notice={"©2019 Nammu21, Inc."} credit={"Website by Future Two, LLC"} stopSignal={setStopSignal}/>
			</div>
			
			<div id={"tut-overlay"} className={"tut-overlay opacityOff"}/>
			<img id="img-tutorial" src={imgTutorial} className={"img-tutorial opacityOff"}/>
		</div>
	);
}

export default App;
