import React from 'react';
import './App.css';

class Subtitle extends React.Component {

    constructor(props) {
        super(props);
        this.copy = "";
        this.animateclass = "subtitle";
    }

    render() {
        this.copy = this.props.catName;
        if (this.copy) {
            this.animateclass = "subtitle-animatein";
        } else {
            this.animateclass = "subtitle";
        }

        return (
            <div id="subtitle" className={this.animateclass}>
                {this.copy}
            </div>
        )
    }
}

export default Subtitle;
