import React from 'react';
import './App.css';
import Subtitle from './Subtitle';

class Header extends React.Component {
	
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}
	
	onClick(event) {
		if (event.currentTarget.id !== 'ham-button') {
			this.props.setCatReq(parseInt(event.currentTarget.id));
		}
	}
	
	render() {
		return (
			<div className="nHeader">
				<img src={this.props.logo} id="nLogo" alt="" onClick={(e) => { document.location.reload() } }/>
				<Subtitle catName={this.props.catName}/>
				<nav className={"hamburger"} role="navigation">
					<div id="menuToggle">
						<input type="checkbox" onClick={this.onClick} id={"ham-button"}/>
						<span></span>
						<span></span>
						<span></span>
						<ul id="menu">
							<li><a onClick={this.onClick} id="0" className={(this.props.catView === 0) ? "boldOn" : "boldOff"} href="#">HOME</a></li>
							<li><a onClick={this.onClick} id="1" className={(this.props.catView === 1) ? "boldOn" : "boldOff"} href="#">PROVISION INVENTORY</a></li>
							<li><a onClick={this.onClick} id="2" className={(this.props.catView === 2) ? "boldOn" : "boldOff"} href="#">NAMMU INVENTORY MATRIX</a></li>
							<li><a onClick={this.onClick} id="3" className={(this.props.catView === 3) ? "boldOn" : "boldOff"} href="#">CORE DOCUMENT METRICS</a></li>
							<li><a onClick={this.onClick} id="4" className={(this.props.catView === 4) ? "boldOn" : "boldOff"} href="#">NAMMU NAVIGATION</a></li>
							<li><a onClick={this.onClick} id="5" className={(this.props.catView === 5) ? "boldOn" : "boldOff"} href="#">CONTACT</a></li>
						</ul>
					</div>
				</nav>
			
			</div>
		)
	}
}

export default Header;
